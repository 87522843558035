export const defaultValuesForASASearch: any = {
  MainNet: {
    tokenId: "31566704",
  },
  TestNet: {
    tokenId: "10458941",
  },
  localhost: {
    tokenId: "23",
  },
};
