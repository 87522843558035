export const RoutesData = {
  // pages
  Presentation: { path: "/" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/dashboard/transactions" },
  ContractOptions: { path: "/dashboard/contract-options" },
  NewFinanceDealContract: {
    path: "/dashboard/new-finance-deal-contract",
  },
  FungibleTokenContract: {
    path: "/dashboard/new-fungible-token-contract",
  },
  CashBuy: {
    path: "/dashboard/cash-buy",
  },
  Settings: { path: "/dashboard/settings" },
  Support: { path: "/dashboard/support" },
  Test: { path: "/dashboard/test" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/examples/sign-in" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "*" },
  ServerError: { path: "/examples/500" },

  // Collapsible Nav Menu
  TokenOptions_ASAs: {
    path: "/dashboard/tokens/asa",
  },
  TokenOptions_NFTs: {
    path: "/dashboard/tokens/nft",
  },

  // components
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
